import {getElementList} from 'SCRIPT_UTILS/getElementList';
import TabbedSlideshow from './TabbedSlideshow';

export function initSlideshowCollection(
  baseSelector = '.js-multi-view-slideshow'
): boolean {
  if (!baseSelector) {
    return false;
  }
  const slideshowElementsList = getElementList<HTMLElement>(baseSelector);

  // exit if elements are not available
  if (slideshowElementsList.length === 0) {
    return false;
  }

  // reset the slideshow collection
  slideshowElementsList.forEach((slideshowElem) => {
    return new TabbedSlideshow(slideshowElem);
  });

  return true;
}
