const options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.7,
};

const callback = (entries, observer) => {
  entries.forEach((entry: IntersectionObserverEntry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add('visible');
    }
  });
};

const teasers = document.querySelectorAll('.cmp-infographic .teaser');

const observer = new IntersectionObserver(callback, options);

teasers.forEach((el) => {
  observer.observe(el);
});
