export default function init() {
  const historyComponents =
    document.querySelectorAll<HTMLDivElement>('.sw-history');

  historyComponents.forEach((historyEl): void => {
    const entryListItems = historyEl.querySelectorAll<HTMLOListElement>(
      '.sw-history__entryListItem'
    );

    entryListItems.forEach((entryListItemEl, i): void => {
      entryListItemEl.classList.toggle(
        'sw-history__entryListItem--reverse',
        !!(i % 2)
      );
    });
  });
}

init();
