import {Gesture} from 'SCRIPTS/interfaces';
import {getElementList} from 'SCRIPT_UTILS/getElementList';
import swipeEventHelper from 'SCRIPT_UTILS/swipeEventHelper';

class TabbedSlideshow {
  private lastActiveSlide = 0;
  private activeSlide = 0;
  private viewElement: HTMLElement | null = null;
  private pagerWrapElement: HTMLElement | null = null;
  private pagerListElement: HTMLElement | null = null;
  private pagerIndicator: HTMLElement | null = null;
  private slideshowElementsCollection: Element[][] = [];
  private sliderElements: HTMLElement[] = [];
  private actionElementsList: HTMLButtonElement[] = [];
  private activeCssClass = 'jsa-isActive';

  constructor(viewElement: HTMLElement) {
    this.viewElement = viewElement;

    this.init();
  }

  //
  // SETUP METHODS
  // ------------------------------

  init(): void {
    const hasElem = this.setElements();

    if (hasElem) {
      this.setEventListeners();
      this.setInitialSlide();
    }
  }

  setElements(): boolean {
    // Exit early if there is no element available
    if (this.viewElement === null) {
      return false;
    }

    this.sliderElements = getElementList<HTMLElement>(
      '[data-target="slideshow"]',
      this.viewElement
    );
    this.slideshowElementsCollection = this.sliderElements.map((sliderWrap) => {
      return Array.from(sliderWrap.children);
    });
    this.pagerWrapElement = this.viewElement.querySelector(
      '[data-target="pager"]'
    );
    this.pagerListElement = this.viewElement.querySelector(
      '[data-target="pagerList"]'
    );
    this.actionElementsList = getElementList<HTMLButtonElement>(
      '[data-action]',
      this.viewElement
    );

    // creating indicator for pager tabs
    this.pagerIndicator = document.createElement('div');
    this.pagerIndicator.classList.add('cmp-cc-pagination-tabs__indicator');
    this.pagerWrapElement?.appendChild(this.pagerIndicator);

    return true;
  }

  setInitialSlide(): void {
    this.updateViewStateActiveSlide(this.activeSlide, this.lastActiveSlide);
  }

  /**
   * Leveraging event bubbling to the wrapping component
   */
  setEventListeners(): void {
    this.actionElementsList.forEach((actionElem) => {
      actionElem.addEventListener('click', this.clickActionHandler);
    });

    this.sliderElements.forEach((slideshowWrapper) => {
      swipeEventHelper(slideshowWrapper, this.gestureHandlerCallback);
    });
  }

  //
  // EVENT HANDLERS
  // ------------------------------

  /**
   * Clickable elements will be identified by elements with a [data-action] attribute.
   * Value for [data-action] attribute determines what action to take when the element has been clicked.
   *
   * @param event
   */
  clickActionHandler = (event: Event): void => {
    // exit early if not interacting with an element that has attribute [data-action]
    if (
      event.currentTarget === null ||
      !(event.currentTarget as HTMLElement).hasAttribute('data-action')
    ) {
      return;
    }
    const actionElement = event.currentTarget as HTMLElement;
    const actionData = actionElement.dataset;

    if (actionData.action === 'goto' && actionData.goto) {
      this.gotoSlide(Number(actionData.goto));
    } else if (actionData.action === 'prev') {
      this.prevSlide();
    } else if (actionData.action === 'next') {
      this.nextSlide();
    }
  };

  gestureHandlerCallback = (gestureData: Gesture): void => {
    // exit early if it is not a swipe gesture which would mean that it is a tap gesture
    if (!gestureData.isSwipe) {
      return;
    }

    if (gestureData.direction === 'right') {
      this.prevSlide();
    } else if (gestureData.direction === 'left') {
      this.nextSlide();
    }
  };

  //
  // SLIDE STATE MANAGEMENT
  // ------------------------------

  /**
   * Update active slide values based on a specific index.
   *
   * @param slideIndex
   */
  gotoSlide(slideIndex: number): void {
    this.lastActiveSlide = this.activeSlide;
    this.activeSlide = slideIndex;

    this.updateViewStateActiveSlide(this.activeSlide, this.lastActiveSlide);
  }

  /**
   * Updates the active slide index to the previous slide index based on the current active slide.
   * This WILL NOT move to the last slide when at the beginning of the available slides
   *
   * @returns
   */
  prevSlide(): void {
    // exit early if on first slide
    if (this.activeSlide === 0) {
      return;
    }
    const newSlide = this.activeSlide - 1;

    this.gotoSlide(newSlide);
  }

  /**
   * Updates the active slide index to the next slide index based on the current active slide.
   * This WILL NOT move to the first slide when at the end of the available slides.
   *
   * @returns
   */
  nextSlide(): void {
    // exit early if on the last slide
    if (this.activeSlide === this.slideshowElementsCollection[0].length - 1) {
      return;
    }
    const newSlide = this.activeSlide + 1;

    this.gotoSlide(newSlide);
  }

  //
  // VIEW MANIPULATIONS
  // ------------------------------

  deactivateTabAndSlidePanel(slideElem: HTMLElement): void {
    const tabButton = slideElem.querySelector('[data-action="goto"]');

    // update old active slide/button to not selected
    if (tabButton !== null) {
      slideElem.setAttribute('aria-selected', 'false');
    } else {
      slideElem.setAttribute('aria-hidden', 'false');
    }

    slideElem.classList.remove(this.activeCssClass);
  }

  activateTabAndSlidePanel(slideElem: HTMLElement): void {
    const tabButton = slideElem.querySelector('[data-action="goto"]');

    // update new active slide to selected
    if (tabButton !== null) {
      slideElem.setAttribute('aria-selected', 'true');
    } else {
      slideElem.setAttribute('aria-hidden', 'true');
    }

    slideElem.classList.add(this.activeCssClass);
  }

  updateViewStateActiveSlide(activeIndex: number, originIndex: number): void {
    this.slideshowElementsCollection.forEach((slidesSet) => {
      this.deactivateTabAndSlidePanel(slidesSet[originIndex] as HTMLElement);
      this.activateTabAndSlidePanel(slidesSet[activeIndex] as HTMLElement);
    });

    this.updateViewActionState(activeIndex, originIndex);
  }

  updateViewActionState(activeIndex: number, originIndex: number): void {
    // only need the collection for generic information like length
    const slideList = this.slideshowElementsCollection[0];

    // disable next/prev if on last/first slide
    this.actionElementsList.forEach((itemElem) => {
      const actionElem = itemElem;
      const actionData = actionElem.dataset;

      if (actionData.action === 'prev' && activeIndex === 0) {
        actionElem.disabled = true;
      } else if (actionData.action === 'prev' && activeIndex > 0) {
        actionElem.disabled = false;
      }

      if (
        actionData.action === 'next' &&
        activeIndex === slideList.length - 1
      ) {
        actionElem.disabled = true;
      } else if (
        actionData.action === 'next' &&
        activeIndex < slideList.length - 1
      ) {
        actionElem.disabled = false;
      }
    });

    // UPDATE PAGINATION
    if (this.pagerListElement !== null && this.pagerIndicator !== null) {
      this.updatePagerState(
        activeIndex,
        originIndex,
        this.pagerListElement,
        this.pagerIndicator
      );
    }
  }

  updatePagerState(
    activeIndex: number,
    originIndex: number,
    pagerListElem: HTMLElement,
    pagerIndrElement: HTMLElement
  ): void {
    const activeChildElem = pagerListElem.children[activeIndex] as HTMLElement;
    const leftPxDistanceInt: number = activeChildElem?.offsetLeft;
    const indicatorWidth = activeChildElem.clientWidth;

    pagerIndrElement.style.width = `${indicatorWidth}px`;
    pagerListElem.style.transform =
      activeIndex === 0 ? '' : `translate(-${leftPxDistanceInt}px, 0)`;
    // deselect the original active tab
    this.deactivateTabAndSlidePanel(
      pagerListElem.children[originIndex] as HTMLElement
    );
    // select the new active tab
    this.activateTabAndSlidePanel(activeChildElem as HTMLElement);
  }
}

export default TabbedSlideshow;
